import PartnershipsPage from "./help/_partnershipsPage";

export default PartnershipsPage

export const frontmatter = {
  pageMetaData: {
    refKey: "partnerships",
    title: "SimpleBackups Partners",
    description: "We build strong partnerships with storage & platform providers helping us improving our database and server backup solutions",
    author: "simplebackups.com"
  }
}
