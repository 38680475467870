import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import filter from "lodash/filter"
import get from "lodash/get"
import { JSONLD, Generic } from 'react-structured-data'

const defaultStructuredData = () => (
  <JSONLD dangerouslyExposeHtml="true">
    <Generic type="WebApplication" jsonldtype="WebApplication" schema={{
      "applicationCategory": "Backup solution",
      "operatingSystem": "all",
      "url": "https://simplebackups.com",
      "name": "SimpleBackups",
      "description": "Automate backups for all your servers, websites, databases (MySQL, PostgreSQL, MongoDB, Redis) and storage. Connect any provider, quick recovery, and peace of mind.",
      /*"aggregateRating": {
        "@type": "aggregateRating",
        "ratingValue": "4.8",
        "ratingCount": "568",
      }*/
    }}/>
  </JSONLD>
)

function SEO(props) {
  let { description, author, lang, meta, title, refKey, image } = props;
  const { site, allJavascriptFrontmatter} = useStaticQuery(
    graphql`
    query {
      allJavascriptFrontmatter {
        edges {
          node {
            frontmatter {
              pageMetaData {
                description
                author
                refKey
                title
              }
            }
            id
          }
        }
      }
      site {
        siteMetadata {
          title
          description
          author
        }
      }
    }
  `)

  const pageMetaData = filter(allJavascriptFrontmatter.edges, (o) => {
    const nodeKey = get(o, 'node.frontmatter.pageMetaData.refKey')
    return (refKey === nodeKey) ? true : false
  });

  const metaData = (pageMetaData.length > 0) ? pageMetaData[0].node.frontmatter.pageMetaData : site.siteMetadata
  const metaAuthor = author || metaData['author']
  const metaDescription = description || metaData.description
  const metaTitle = title || metaData.title
  const metaImage = "https://simplebackups.com" + (image || "/images/share-image.png")

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={metaTitle}
        titleTemplate={`%s`}
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            property: `og:image`,
            content: metaImage,
          },
          {
            property: `og:url`,
            content: "https://simplebackups.com",
          },
          {
            name: `og:author`,
            content: metaAuthor,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: metaAuthor,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
      />
      { defaultStructuredData() }
    </>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
  image: PropTypes.string,
  structuredData: PropTypes.any
}

export default SEO
