import {Layout, Hero} from "components/layout"
import SEO from "components/seo"
import { Section, Typography, Container, Row, SectionTitle } from "components/ui"
import React from "react"
import Image from "components/image"
import ContactForm from './_contactForm'

const PartnershipsPage = () => {
    return (
      <Layout>
        <SEO refKey="partnerships" />
        <Section color="alt">
          <Container>
            <Row>
              <div className="col-md-12 text-center">
                <h1 className="font-heading tracking-tight text-5xl md:text-6xl md:leading-tight font-black">Partnerships</h1>
                <Typography tag="h3">We work with cloud technology companies as well as developer ecosystems.</Typography>
              </div>
            </Row>
          </Container>
        </Section>

        <Section padding>
          <Container>
            <Row>
              <div className="col-md-12">
                <SectionTitle><Typography tag="h2" className="text-left">Preferred Partners</Typography></SectionTitle>
                <ul className="flex flex-wrap gap-10 w-auto">
                  <li className="flex items-center h-52 w-52 relative justify-center bg-gray-50 rounded-xl p-12">
                    <Image filename="digitalocean.png" alt="DigitalOcean Spaces Backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-52 w-52 relative justify-center bg-gray-50 rounded-xl p-12">
                    <Image filename="backblaze.png" alt="Backblaze backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-52 w-52 relative justify-center bg-gray-50 rounded-xl p-12">
                    <Image filename="wasabi.png" alt="Wasabi backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-52 w-52 relative justify-center bg-gray-50 rounded-xl p-12">
                    <Image filename="filebase.png" alt="Filebase backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-52 w-52 relative justify-center bg-gray-50 rounded-xl p-12">
                    <Image filename="synology.png" alt="Synology backup" style={{width: '100%'}}/>
                  </li>
                </ul>

                <SectionTitle><Typography tag="h2" className="text-left mt-32">Storage Partners</Typography></SectionTitle>
                <ul className="flex flex-wrap gap-6 w-auto">
                  <li className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="digitalocean.png" alt="DigitalOcean Spaces Backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="aws.png" alt="AWS backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="dropbox.png" alt="Dropbox" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="backblaze.png" alt="Backblaze backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="wasabi.png" alt="Wasabi backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="gdrive.png" alt="Google Cloud backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="filebase.png" alt="Filebase backup" style={{width: '100%'}}/>
                  </li>
                  <li className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="microsoft-azure.png" alt="Azure backup" style={{width: '100%'}}/>
                  </li>
                </ul>

                <SectionTitle><Typography tag="h2" className="text-left mt-32">Integration Partners</Typography></SectionTitle>
                <dl className="flex flex-wrap gap-6 w-auto">
                  <a href="https://www.digitalocean.com/" className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="digitalocean.png" alt="DigitalOcean Droplet & Spaces Snapshots" style={{width: '100%'}}/>
                  </a>
                  <a href="https://aws.amazon.com/ec2/" className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="aws.png" alt="AWS EC2 Snapshots" style={{width: '100%'}}/>
                  </a>
                  <a href="https://www.hetzner.com/" className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="hetzner.png" alt="Hetzner Cloud VPS Snapshots" style={{width: '100%'}}/>
                  </a>
                  <a href="https://www.exoscale.com/" className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="exoscale.png" alt="Exoscale Server Snapshots" style={{width: '100%'}}/>
                  </a>
                  <a href="https://www.vultr.com" className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="vultr.png" alt="Vultr Cloud Compute Snapshots" style={{width: '100%'}}/>
                  </a>
                  <a href="https://upcloud.com/" className="flex items-center h-32 w-32 relative justify-center bg-gray-50 rounded-xl p-8">
                    <Image filename="upcloud.png" alt="UpCloud Storage Snapshots" style={{width: '100%'}}/>
                  </a>
                </dl>
              </div>
            </Row>
          </Container>
        </Section>
        <Section color="dark">
          <Container>
            <Row>
              <div className="col-md-12">
                <SectionTitle className="text-center">
                  <Typography tag="h2" className="">Interested in partnering with SimpleBackups?</Typography>
                  <p>We'd love to hear from you! Please fill out the form below and we will be in touch soon.</p>
                </SectionTitle>
                <ContactForm />
              </div>
            </Row>
          </Container>
       </Section>

      </Layout>
    )
}

export default PartnershipsPage
