import React from "react"
import styled from 'styled-components';
import tw from "tailwind.macro"
import { Button, Alert } from 'components/ui'

let SInput = styled.input`
  ${tw`appearance-none block w-full bg-gray-lighter text-gray-700 border border-gray-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-dark`}
`
const STextArea = styled.textarea`
  ${tw`appearance-none block w-full bg-gray-lighter text-gray-700 border border-gray-lighter rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500 text-dark`}
`
const SLabel = styled.label`
  ${tw`block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2`}
`

const encode = (data) => {
  return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
}

const ContactForm = () => {
  return (
    <>
      <form action="https://formspree.io/f/mwkywyaq" method="POST">
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <p className="mb-3">
          <SLabel>Your name:</SLabel>
          <SInput key="name" type="name" name="name"/>
        </p>
        <p className="mb-3">
          <SLabel>Your email:</SLabel>
          <SInput key="email" type="email" name="email"/>
        </p>
        <p className="mb-3">
          <SLabel>Your message:</SLabel>
          <STextArea key="message" type="message" name="message"/>
        </p>
        <p className="mb-3">
          <Button tag="button" type="submit" size="medium" color="primary">Send</Button>
        </p>
      </form>
    </>
  );
}

export default ContactForm;
